export const initialState = { 
    token: '',
    users: {
        "name": "",
        "age": "tring",
        "phone_number": "",
        "email": "",
        "password": "",
        "state": "",
        "city": "",
        "genre": "",
        "breed": "",
        "active": false,
        "trusted": false,
        "role": "",
        "first_access": false,
        "settings": {
            "user": "",
            "service_notifications": [
            ""
            ],
            "all_notifications": false,
            "push_token": "",
            "_id": ""
        },
        "_id": "",
        "createdAt": "",
        "updatedAt": ""
    },
    sendcode: {
        "user_id": "",
        "code": "",
        "active": false
    },
    username: ""
 }